import { lessonStatus, paidStatus } from "./LessonStatus";

const Appointment = (model) => {
    const data = model.data.appointmentData

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const apptStart = new Date(data.startDate);
    const apptEnd = new Date(data.endDate);
    const dateTimeString = `${monthNames[apptStart.getMonth()]} ${apptStart.getDate()} ${apptStart.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} - ${apptEnd.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
    
    return(
        
        <div className="dx-tooltip-appointment-item">
            <div className="dx-tooltip-appointment-item-content">
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div className="dx-tooltip-appointment-item-content-subject">{data.text}</div>
                    <div className="dx-tooltip-appointment-item-content-date">{lessonStatus[data.status].text}</div>
                    <div className="dx-tooltip-appointment-item-content-date">{paidStatus[data.paid].text}</div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div className="dx-tooltip-appointment-item-content-date">{dateTimeString}</div>
                    <div className="dx-tooltip-appointment-item-content-date">{data.horseName}</div>
                </div>
            </div>
        </div>
    );
}



export default Appointment;