import React from 'react';
import { Grid, Card, CardContent, Stack, Typography, IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WeekButtons from "../WeekButtons/WeekButtons";

import "../../Global.css";

const months = [
    "January",
    "February",
    "March",
    "April", 
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

const StudentCard = ({student, recurrence, horseData, width, updateRecurrence, handleEdit}) => {
    const horse = (horseData.filter((horse) => { return horse.id === student.horse }))[0]
    
    let birthdate, age

    if (student.birthdate) {
        const today = new Date()
        const stdBd = new Date (student.birthdate)
        age = today.getFullYear() - stdBd.getFullYear();
        var m = today.getMonth() - stdBd.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < stdBd.getDate())) {
            age--;
        }
        birthdate = ((stdBd.getDate()+1) + " " + months[stdBd.getMonth()] + " " + stdBd.getFullYear())
    }

    return (
        <>
            <Grid item xs={16} md={5} style={{marginBottom: "1em"}}>
                <Card>
                    <CardContent>
                        <Stack spacing={1}>
                            <Typography component={'span'} variant="h5" style={{display: "flex", justifyContent: "space-between", paddingBottom: 5, textAlign: "left"}}>
                                <div>{(student.fullName) ? student.fullName : "? ? ?"}</div>
                                <IconButton onClick={() => { handleEdit(student) }}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Typography>
                            <Typography component={'span'} variant="body2" color="text.secondary" style={{display: "flex", justifyContent: "space-between"}}>
                                <div>Birthday:</div>
                                <div>{(student.birthdate) ? birthdate : "None Set"}</div>
                            </Typography>
                            <Typography component={'span'} variant="body2" color="text.secondary" style={{display: "flex", justifyContent: "space-between"}}>
                                <div>Age:</div>
                                <div>{(student.birthdate) ? age : "None Set"}</div>
                            </Typography>
                            <Typography component={'span'} variant="body2" color="text.secondary" style={{display: "flex", justifyContent: "space-between"}}>
                                <div>Phone:</div>
                                <div>{(student.phone) ? student.phone : "None Set"}</div>
                            </Typography>
                            <Typography component={'span'} variant="body2" color="text.secondary" style={{display: "flex", justifyContent: "space-between"}}>
                                <div>Email:</div>
                                <div>{(student.email) ? student.email : "None Set"}</div>
                            </Typography>
                            <Typography component={'span'} variant="body2" color="text.secondary" style={{display: "flex", justifyContent: "space-between"}}>
                                <div>Default Horse:</div>
                                <div>{(horse) ? horse.text : "None Set"}</div>
                            </Typography>
                            <Typography component={'span'} variant="body2" color="text.secondary" style={{display: "flex", justifyContent: "space-between"}}>
                                <div>Default Price:</div>
                                <div>{(student.price) ? student.price : "None Set"}</div>
                            </Typography>
                            <WeekButtons data={recurrence} width={width} student={student} updateRecurrence={updateRecurrence}/>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

export default StudentCard