import React from 'react'
import StudentCard from './StudentCard'
import { Grid } from '@material-ui/core'

const StudentCards = ({students, recurrence, horseData, width, updateRecurrence, handleEdit}) => {

    let cards = []
    for (const [key, value] of Object.entries(students)) {
        cards.push(
            <StudentCard 
                key={key} 
                student={value} 
                recurrence={recurrence[value.id]} 
                horseData={horseData} width={width} 
                updateRecurrence={updateRecurrence} 
                handleEdit={handleEdit} 
            />
        )
    }

    return (
        <div>
            <Grid container columns={16} justifyContent="space-around">
                { cards }
            </Grid>
        </div>        
    )
}

export default StudentCards