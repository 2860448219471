import React, {useState, useEffect, useLayoutEffect} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import hero from "../assets/hero.png";
import trainer from "../assets/trainer_img.jpg";
import farm from "../assets/farm.jpg";


function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export const Home = () => {
    const width = useWindowSize()

    const [heroSize, setHeroSize] = useState([0, 0])

    useEffect(() => {

        const sizes = [
            document.getElementById("hero_container").offsetHeight,
            document.getElementById("hero_container").offsetWidth,
        ]

        setHeroSize(sizes)

    }, [width]);
    

    return (
        <div style={classes.root}>
            <div style={classes.homeImage} id="hero_container">
                <img src={hero} style={{borderRadius: 5, height: heroSize[0], width: heroSize[1]}} alt="Rich Run Farm Banner"/>
                {(width < 960) ? 
                <h3 style={classes.homeImageText}>Rich Run Farm</h3> :
                <h1 style={classes.homeImageText}>Rich Run Farm</h1>
                }
                
            </div>
            <Paper elevation={2} style={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <img style={classes.intextImage} alt="alt1" src={trainer} />
                    </Grid>
                    <Grid item style={classes.textContainer} xs={12} md={9} >
                        <p style={classes.text}>
                            Here at Rich Run Farm, we're happy to have Victoria Hopkins-Whitaker as our Head Trainer and Manager. <br></br>
                            Alongside a Bachelor's of Science in Animal Science from the University of Tennessee, she has acrued more than 15 years of experience in the equine industry. While pursuing her degree, Victoria participated in internships attached to both the race horse and performance horse industry. Additionally, Victoria has many years of experience in the 3-day eventing world, competing at the Prelim/Training level while working and training under Caitlin Romeo. During high school, she was also an active member of Pony Club and acheieved the C1 certification. Now, as she brings up new horses to re-enter competition, Victoria is sharing her passion and offering riding lessons here at Rich Run Farm.
                        </p>
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={2} style={classes.paper}>
                <Grid container spacing={3} direction="row-reverse">
                    <Grid item xs={12} md={3}>
                        <img style={classes.intextImage} alt="alt2" src={farm} />
                    </Grid>
                    <Grid item style={classes.textContainer} xs={12} md={9} >
                        <p style={classes.text}>
                            Rich Run Farm is a private equine facility that sits on 394 acres of beautiful mountain property in Stuart, Virginia. Rich Run Farm has been in the Hopkins family for generations and we are proud to offer our services and pass our passions on to you! Here at the farm, we offer horse back riding lessons, trailer-in trail riding, hay sales, and more...
                        </p>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};


const classes = {
    root: {
      backgroundColor: "#fff",
      margin: "auto",
      flexGrow: 1,
      maxWidth: 1250,
      paddingTop: 30,
      paddingLeft: 25,
      paddingRight: 25,
      height: "calc(100vh-30px)"
    },
    paper: {
      minHeight: 225,
      marginBottom: 25,
      padding: 16,
      textAlign: "center",
      color: "rgba(0,0,0,0.6)"
    },
    textContainer: {
      margin: "auto"
    },
    text: {
      fontSize: 16,
      padding: 5
    },
    intextImage: {
        borderRadius: 5,
        height: 225,
        width: 225
    },
    homeImage: {
        textAlign: "Left",
        borderRadius: 5,
        width: "100%",
        height: 0,
        paddingBottom: "40%",
        marginBottom: 30,
        position: "relative"
    },
    homeImageText: {
        color: "white",
        paddingLeft: 75,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)"
    }
  }
  