import { GoogleLogin } from 'react-google-login';
import { useEffect } from 'react';
import Button from '@mui/material/Button';

function LoginLogoutButton({ loginData, setLoginData}) {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    const datestring = yyyy+"-"+mm+"-"+dd

    //check local storage for existing loginData, load to state if it exists
    useEffect(() => {
        const localLoginData = ( JSON.parse(localStorage.getItem('RRFUserData')) );
        if (localLoginData && (localLoginData.date === datestring)) {
            localLoginData['api_key'] = localStorage.getItem('RRFApiKey')
            updateLoginData(JSON.stringify(localLoginData))
        } else {
            updateLoginData( null )
        }
    }, [])

    //Handle local storage and state in parallel
    const updateLoginData = (data) => {
        // console.trace()
        if (data) {
            var json_data = JSON.parse(data)
            // console.log(json_data)
            localStorage.setItem('RRFApiKey', json_data['api_key'])
            delete json_data['api_key']
            // console.log(json_data)
            setLoginData(json_data)
            localStorage.setItem('RRFUserData', JSON.stringify(json_data))
            // console.log(localStorage)
        } else {
            setLoginData()
            localStorage.removeItem('RRFUserData')
            localStorage.removeItem('RRFApiKey')
        }
    }

    const handleLogin = (response) => {
        // console.log(response)
        //Get request to validate Google Auth and create login session
        fetch(process.env.REACT_APP_API_URL + "RRFLogin.php?id_token="+response.tokenId, {
            method: "GET"
        })
        .then(res => res.text())
        // .then(data => data.split("<")[0])
        .then(split => {
            // console.log(split)
            updateLoginData(split)
        })
    }

    const handleLogout = () => {   
        console.log("logout")
        //POST request to remove session from DB
        fetch(process.env.REACT_APP_API_URL + 'RRFLogout.php', {
            method: 'post',
            body: JSON.stringify(loginData)
        });

        //Clear local storage and state
        updateLoginData(null)
    }
    
    const handleFailure = (response) => {
        console.log(response)
    }

    return (
        <div>
            {
                 loginData ? (
                    <div>
                        <Button variant="outlined" size="small" onClick={handleLogout}>Logout</Button> 
                    </div>
                     ) : (
                    <GoogleLogin
                        clientId={process.env.REACT_APP_API_KEY}
                        buttonText="Log in with Google"
                        render={renderProps => (
                            <Button variant="contained" size="small" onClick={renderProps.onClick} disabled={renderProps.disabled}>Log In</Button>
                          )}
                        onSuccess={handleLogin}
                        onFailure={handleFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                )
            } 

            
        </div>
    );
}



export default LoginLogoutButton;
