import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import "./App.css";
import "./Global.css";

import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";

import { Home } from "./Pages/Home";
import { Guidelines } from "./Pages/Guidelines";
import { Resources } from "./Pages/Resources";
import { Contact } from "./Pages/Contact";
import { Trainer } from "./Pages/Trainer";

import PublicNavBar from "./components/PublicNav/PublicNavBar";
import PrivateNavBar from "./components/PrivateNav/PrivateNavBar";

function App() {
    const [loginData, setLoginData] = useState( null )
    const history = useHistory();

    useEffect(() => {
        if (loginData) {
            history.push("/trainer")
        } else {
            history.push("/")
        }
    }, [loginData])

    return (
        <>
            { 
                loginData ? 
                <PrivateNavBar loginData={loginData} setLoginData={setLoginData} /> : 
                <PublicNavBar loginData={loginData} setLoginData={setLoginData} />
            }

            <div className="pages">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/guidelines" component={Guidelines} />
                    <Route path="/resources" component={Resources} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/trainer"><Trainer /></Route>
                </Switch>
            </div>
        </>
    );
}

const AppWrapper = () => {
    return (
        <Router> 
            <App /> 
        </Router>
    );
}

export default AppWrapper;
