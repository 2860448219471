export const lessonStatus = [
    {
        //scheduled
        id: "0",
        color: '#C0C0C0', 
        text: 'Scheduled',
    },
    {
        //cancelled
        id: "1",
        color: '#B22222',  
        text: 'Cancelled',
    },
    {
        //confirmed
        id: "2",
        color: '#337AB7',  
        text: 'Confirmed',
    },
    {
        //complete
        id: "3",
        color: '#7CFC00',  
        text: 'Complete',
    },
]

export const paidStatus = [
    {
        id: "0",
        color: '#C0C0C0', 
        text: "Unpaid",
    },
    {
        id: "1",
        color: '#7CFC00',  
        text: "Paid - Cash",
    },
    {
        id: "2",
        color: '#337AB7',  
        text: "Paid - Venmo",
    },
    {
        id: "3",
        color: '#337AB7',  
        text: "Worked",
    },
]