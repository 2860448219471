import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import LoginLogoutButton from "../LoginLogoutButton";
import "./PrivateNavBar.css";

function PublicNavBar({ loginData, setLoginData }) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
            <div className="nav-logo">
                <NavLink exact to="/" className="nav-logo-text">
                    Rich Run Farm
                </NavLink>
            </div>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li className="nav-item">
                    <NavLink
                        exact
                        to="/trainer"
                        activeClassName="active"
                        className="nav-links"
                        onClick={handleClick}
                    >
                        Dashboard
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        exact
                        to="/trainer/students"
                        activeClassName="active"
                        className="nav-links"
                        onClick={handleClick}
                    >
                        Students
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        exact
                        to="/trainer/schedule"
                        activeClassName="active"
                        className="nav-links"
                        onClick={handleClick}
                    >
                        Schedule
                    </NavLink>
                </li>
                <li className="nav-item">
                    <div className="nav-links margin-adjust">
                        <LoginLogoutButton loginData={loginData} setLoginData={setLoginData}/>
                    </div>
                </li>
            </ul>
            <div className="nav-icon" onClick={handleClick}>
                <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
            </div>
        </div>
      </nav>
    </>
  );
}

export default PublicNavBar;
