import React from 'react';
import '../Global.css';
import Calendar from '../components/Calendar/Calendar';

const Schedule = () => {
  return (
    <div className='container'><Calendar /></div>
  )
}


export default Schedule