import {
	Paper, Grid, Typography, List, ListItem, ListItemText, IconButton, 
	Divider, Dialog, DialogContent, DialogActions, Button, TextField, 
	Snackbar, Alert
		} from '@material-ui/core';
import React, { useEffect, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit';

import "../Global.css";
import { Add } from '@mui/icons-material';

const handleErrors = (response) => {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const Dashboard = () => {
	const [SBOpen, setSBOpen] = useState(false)
    const [SBSeverity, setSBSeverity] = useState("success")
    const [SBMessage, setSBMessage] = useState("")

	const [loaded, setLoaded] = useState(false)
	const [horses, setHorses] = useState([])

	const [horseDialogOpen, setHorseDialogOpen] = useState(false)
	const [horseDialogText, setHorseDialogText] = useState("")
	const [horseDialogMode, setHorseDialogMode] = useState(0) // 0 - none, 1 - new, 2 - edit
	const [horseDialogId, setHorseDialogId] = useState(0)

	const handleSBClose = () => {
        setSBOpen(false)
        setSBSeverity("success")
        setSBMessage("")
    }

	const fetchHorseData = () => {
		fetch(process.env.REACT_APP_API_URL+"RRFHorses.php")
			.then(handleErrors)
			.then(res => res.text())
			.then(raw => raw.replace("~", ""))
			.then(txt => JSON.parse(txt))
			.then(jsn => setHorses(jsn))
			.then(() => setLoaded(true))
	}

	useEffect(() => {
		fetchHorseData()
	}, [])

	const handleHorseDialogChange = (e) => {
		setHorseDialogText(e.target.value)
	}

	const handleHorseEdit = (horse) => {
		setHorseDialogText(horse.text)
		setHorseDialogId(horse.id)
		setHorseDialogMode(2)
		setHorseDialogOpen(true)
	}

	const handleHorseNew = () => {
		setHorseDialogMode(1)
		setHorseDialogOpen(true)
	}

	const horseHandleDelete = () => {
		//API CALL TO DELETE
		fetch(process.env.REACT_APP_API_URL+"RRFHorses.php", {
			method: "PUT",
			body: JSON.stringify({"action": "delete", "id": horseDialogId})
		})
			.then(handleErrors)
			.then(res => res.text())
			.then(raw => raw.replace("~", ""))
			.then(txt => JSON.parse(txt))
			.then(jsn => {
				setSBSeverity(jsn.severity)
				setSBMessage(jsn.message)
				setSBOpen(true)
				return jsn
			})
			.then(data => {
				if (data.severity === "success") {
					fetchHorseData()
				} 
			})
		horseHandleClose()
	}

	const horseHandleClose = () => {
		setHorseDialogOpen(false)
		setHorseDialogMode(0)
		setHorseDialogId(0)
		setHorseDialogText("")
	}

	const horseHandleSubmit = () => {
		switch (horseDialogMode) {
			case 1:
				// API CALL TO INSERT	
				fetch(process.env.REACT_APP_API_URL+"RRFHorses.php", {
					method: "POST",
					body: JSON.stringify({"text" : horseDialogText})
				})
					.then(handleErrors)
					.then(res => res.text())
					.then(raw => raw.replace("~", ""))
					.then(txt => JSON.parse(txt))
					.then(jsn => {
						setSBSeverity(jsn.severity)
						setSBMessage(jsn.message)
						setSBOpen(true)
						return jsn
					})
					.then(data => {
						if (data.severity === "success") {
							fetchHorseData()
						} 
					})
				break;
			case 2:
				// API CALL TO UPDATE	
				fetch(process.env.REACT_APP_API_URL+"RRFHorses.php", {
					method: "PUT",
					body: JSON.stringify({"action": "update", "id": horseDialogId, "text" : horseDialogText})
				})
					.then(handleErrors)
					.then(res => res.text())
					.then(raw => raw.replace("~", ""))
					.then(txt => JSON.parse(txt))
					.then(jsn => {
						setSBSeverity(jsn.severity)
						setSBMessage(jsn.message)
						setSBOpen(true)
						return jsn
					})
					.then(data => {
						if (data.severity === "success") {
							fetchHorseData()
						} 
					})
				break;
			default:
				break;
		}
		horseHandleClose()
	}

	const horseList = () => {
		let hList = []
		if (horses) {
			horses.map((horse) => {
				hList.push(
					<ListItem
						key={horse.id}
						secondaryAction={
							<IconButton edge="end" aria-label="edit" onClick={() => {handleHorseEdit(horse)}}>
								<EditIcon />
							</IconButton>
						}
					>
						<ListItemText
							primary={horse.text}
						/>
					</ListItem>
				)
				return horse
			})
		}
		return hList
	}

    return (
        <div className='container'>
			{   (loaded) ?

			
				<Grid container spacing={3} style={{paddingTop: 30}}>
					{/* <Grid item xs={12}>
						<Paper>
							Graphing
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper>
							Income
						</Paper>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper>
							Expenses
						</Paper>
					</Grid> */}
					<Grid item xs={12} md={2}>
						<Paper style={{padding: 15, paddingTop: 5, textAlign: "left"}}>
							<List>
								<ListItem
									key="add_button"
									secondaryAction={
										<IconButton edge="end" aria-label="add" onClick={handleHorseNew}>
											<Add />
										</IconButton>
									}
								>
									<Typography variant="h5" style={{paddingBottom: 5}}>Horses</Typography>
								</ListItem>
								<Divider style={{marginBottom: 5}} />
								{
									horseList()
								}
							</List>
						</Paper>
					</Grid> 
				</Grid>
				
				
				:
				<Typography style={{paddingLeft: 30}}>Loading...</Typography>
			}

			<Dialog open={horseDialogOpen}>
				<DialogContent>
					<TextField
						required
						label="Horse Name"
						size="small"
						value={horseDialogText}
						variant="standard"
						onChange={handleHorseDialogChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={horseHandleDelete}>Delete</Button>
					<Button onClick={horseHandleClose}>Cancel</Button>
					<Button 
						onClick={horseHandleSubmit}
						disabled={(horseDialogText.length === 0)}
					>Submit</Button>
        		</DialogActions>
			</Dialog>

			<Snackbar open={SBOpen} autoHideDuration={3000} onClose={handleSBClose}>
				<Alert severity={SBSeverity}>
					{SBMessage}
				</Alert>
			</Snackbar>
        </div>
    );
}

export default Dashboard