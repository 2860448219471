import React, { useState, useEffect } from 'react';
import { Grid, Paper, Modal, Alert, Snackbar, Typography, TextField, MenuItem, Button, Fab, IconButton } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";

import StudentCards from '../components/StudentCard/StudentCards';
import '../Global.css';

const handleErrors = (response) => {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const Students = () => {
    const [mounted, setMounted] = useState(false)
    const [studentsLoaded, setStudentsLoaded] = useState(false)
    const [horsesLoaded, setHorsesLoaded] = useState(false)
    const [width, setWidth] = useState(window.innerWidth)
    const [modalOpen, setModalOpen] = useState(false)

    const [SBOpen, setSBOpen] = useState(false)
    const [SBSeverity, setSBSeverity] = useState("success")
    const [SBMessage, setSBMessage] = useState("")

    const [studentData, setStudentData] = useState()
    const [horseData, setHorseData] = useState()
    const [recurrenceData, setRecurrenceData] = useState()

    const [formCurrentId, setFormCurrentId] = useState()
    const [formFirstName, setFormFirstName] = useState("")
    const [formLastName, setFormLastName] = useState("")
    const [formPhoneNumber, setFormPhoneNumber] = useState("")
    const [formEmailAddress, setFormEmailAddress] = useState("")
    const [formHorse, setFormHorse] = useState('')
    const [formPrice, setFormPrice] = useState("35")
    const [formBirthday, setFormBirthdayFormatted] = useState("")
    const [formErrors, setFormErrors] = useState([])
    const [formShowErrors, setFormShowErrors] = useState(false)

    const setFormBirthday = (inDate) => {
        let newDate = new Date(inDate)
        setFormBirthdayFormatted(newDate.getFullYear()+"-"+("0" + (newDate.getMonth()+1)).slice(-2)+"-"+("0" + (newDate.getDate()+1)).slice(-2))
    }

    const fetchData = () => {
        setStudentsLoaded(false)
        setHorsesLoaded(false)
        fetch(process.env.REACT_APP_API_URL+"RRFStudents.php")
        .then(handleErrors)
        .then(res => res.text())
        .then(raw => raw.replace("~", ""))
        .then(txt => JSON.parse(txt))
        .then(jsn => initState(jsn))
    
        fetch(process.env.REACT_APP_API_URL+"RRFHorses.php")
        .then(handleErrors)
        .then(res => res.text())
        .then(raw => raw.replace("~", ""))
        .then(txt => JSON.parse(txt))
        .then(jsn => initHorses(jsn))

        setMounted(true)
    }

    if (!mounted) {
        fetchData()
    }

    const initState = (jsnData) => {
        let stdData = {}
        let rcrData = {}
        jsnData.forEach(element => {
            let temp = JSON.parse(element.recurrence)
            if (temp && temp.length === 0) temp = null
            rcrData[element.id] = temp

            let stdTemp = element
            delete stdTemp['recurrence']
            stdData[element.id] = stdTemp
        })
        setStudentData(stdData)
        setRecurrenceData(rcrData)
        setStudentsLoaded(true)
    }

    const initHorses = (horses) => {
        setHorseData(horses)
        setHorsesLoaded(true)
    }

    const updateRecurrence = (data, student) => {
        let newData = JSON.parse(JSON.stringify(recurrenceData))

        if (data) {
            //Some data remained after update
            newData[student.id] = data
            
            setRecurrenceData(newData)
            recurrenceUpdate(student.id, data)
            
        } else {
            //No data remained after update
            delete newData[student.id]
            //Remove recurrence data for student.
            setRecurrenceData(newData)  
            recurrenceUpdate(student.id, null)
        }
    }

    const recurrenceUpdate = (id, data) => {

        let updateBody = {
            action: "recurrence",
            id: id,
            data: data
        }

        fetch(process.env.REACT_APP_API_URL+"RRFStudents.php", {
            method: "PUT",
            body: JSON.stringify(updateBody)
        })
        .then(handleErrors)
        .then(res => res.text())
        .then(raw => raw.replace("~", ""))
        .then(txt => JSON.parse(txt))
        .then(jsn => {
            setSBSeverity(jsn.severity)
            setSBMessage(jsn.message)
            setSBOpen(true)

            return (jsn.severity === "success")
        })


    }

    const handleSBClose = () => {
        setSBOpen(false)
        setSBSeverity("success")
        setSBMessage("")
    }

    const handleEdit = (e) => {
        const [firstname, lastname] = e.fullName.split(" ")

        setFormCurrentId(e.id)
        setFormFirstName(firstname)
        setFormLastName(lastname)
        if (e.phone) setFormPhoneNumber(e.phone)
        if (e.email) setFormEmailAddress(e.email)
        if (e.horse) setFormHorse(parseInt(e.horse))
        if (e.price) setFormPrice(e.price)
        if (e.birthdate) setFormBirthday(e.birthdate)

        setModalOpen(true)
    }

    const validateForm = () => {
        let valid = true
        let errors = []
        if (!(/^\d+$/.test(formPhoneNumber))) {
            valid = false
            errors.push("Only use numbers in the phone number entry. Ex: 3333333333")
        }
        if (!(/^\d+$/.test(formPrice))) {
            valid = false
            errors.push("Only use numbers in the price entry. Ex: 35")
        }
        if (formPhoneNumber.length !== 10) {
            valid = false
            errors.push("Ensure phone number is 10 digits")
        }
        // if (formEmailAddress !== "" && !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formEmailAddress))) {
        //     valid = false
        //     errors.push("Please check email format.")
        // } 
        
        setFormErrors(errors)
        return valid
    }

    const handleSubmit = () => {

        if (!validateForm()) {
            setFormShowErrors(true)
            return
        } 

        let reqBody = {
            "firstname": formFirstName,
            "lastname": formLastName,
            "phone": formPhoneNumber,
            "birthdate": formBirthday
        }
        if (formEmailAddress !== "") reqBody["email"] = formEmailAddress
        if (formHorse) reqBody["horse"] = String(formHorse)
        if (formPrice) reqBody["default_price"] = formPrice

        if (formCurrentId) {
            reqBody['action'] = "student"
            reqBody['id'] = formCurrentId

            fetch(process.env.REACT_APP_API_URL+"RRFStudents.php", {
                method: "PUT", 
                body: JSON.stringify(reqBody)
            })
            .then(handleErrors)
            .then(res => res.text())
            .then(raw => raw.replace("~", ""))
            .then(txt => JSON.parse(txt))
            .then(jsn => {
                setSBSeverity(jsn.severity)
                setSBMessage(jsn.message)
                setSBOpen(true)
                return jsn
            })
            .then(data => {
                if (data.severity === "success") {
                    closeModal()
                    fetchData()
                } 
            })
        } else {

            fetch(process.env.REACT_APP_API_URL+"RRFStudents.php", {
                method: "POST", 
                body: JSON.stringify(reqBody)
            })
            .then(handleErrors)
            .then(res => res.text())
            .then(raw => raw.replace("~", ""))
            .then(txt => JSON.parse(txt))
            .then(jsn => {
                setSBSeverity(jsn.severity)
                setSBMessage(jsn.message)
                setSBOpen(true)
                return jsn
            })
            .then(data => {
                if (data.severity === "success") {
                    closeModal()
                    fetchData()
                } 
            })

        }
    }

    const openModal = () => {
        setFormPrice("30")
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)

        setFormCurrentId()
        setFormFirstName("")
        setFormLastName("")
        setFormPhoneNumber("")
        setFormEmailAddress("")
        setFormHorse('')
        setFormPrice("")
        setFormBirthday("")
    }

    const handleFormUpdate = (e) => {
        switch(e.target.id) {
            case "form-firstname":
                setFormFirstName(e.target.value)
            break;
            case "form-lastname":
                setFormLastName(e.target.value)
            break;
            case "form-phone":
                setFormPhoneNumber(e.target.value)
            break;
            case "form-email":
                setFormEmailAddress(e.target.value)
            break;
            case "form-price":
                setFormPrice(e.target.value)
            break;
            case "form-birthday":
                let newDate = new Date(e.target.value)
                setFormBirthday(newDate.toString())
            break;
            default:
                setFormHorse(parseInt(e.target.value))
            break;
        }
    }

    useEffect(() => {
        setMounted(true)
    }, [])
    
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return _ => window.removeEventListener('resize', handleResize)
    })

return (
    <div className='container' style={{paddingTop: 25}}>

        {
        (studentsLoaded && horsesLoaded) ? 
            <>
                <StudentCards 
                    students={studentData} 
                    recurrence={recurrenceData} 
                    horseData={horseData}
                    width={width} 
                    updateRecurrence={updateRecurrence} 
                    handleEdit={handleEdit} 
                /> 

                <Fab color="primary" style={{position: "absolute", bottom: 25, right: 25}} onClick={openModal} ><AddIcon /></Fab>

                <Modal open={modalOpen}>
                    <Paper elevation={3} style={modalStyle}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><Typography variant="h5">Student</Typography></Grid>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" variant='standard' margin="dense" id="form-firstname" value={formFirstName} onChange={handleFormUpdate} required label="First Name" style={{width: "98%"}}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" variant='standard' margin="dense" id="form-lastname" value={formLastName} onChange={handleFormUpdate} required label="Last Name" style={{width: "98%"}}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" variant='standard' margin="dense" id="form-phone" value={formPhoneNumber} onChange={handleFormUpdate} required label="Phone Number" style={{width: "98%"}} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                                <TextField size="small" variant='standard' margin="dense" id="form-email" value={formEmailAddress} onChange={handleFormUpdate} label="Email Address" style={{width: "98%"}}/>
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                                <TextField size="small" variant='standard' margin="dense" id="form-horse" value={formHorse} onChange={handleFormUpdate} select label="Default Horse" style={{width: "98%"}}>
                                    {horseData.map((horse) => {
                                        return <MenuItem key={horse.id} value={horse.id}>{horse.text}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" variant='standard' margin="dense" id="form-price" value={formPrice} onChange={handleFormUpdate} label="Default Price" style={{width: "98%"}} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="MuiFormControl-root MuiFormControl-marginDense MuiTextField-root css-4mzek5-MuiFormControl-root-MuiTextField-root" style={{width: "98%"}}>
                                    <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary Mui-required css-1c2i806-MuiFormLabel-root-MuiInputLabel-root" data-shrink="true">Birthday<span aria-hidden="true" className="MuiInputLabel-asterisk MuiFormLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></label>
                                    <div className="MuiInput-root MuiInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall css-1480iag-MuiInputBase-root-MuiInput-root">
                                        <input type='date' id='form-birthday' onKeyDown={(e) => e.preventDefault()} value={formBirthday} onChange={handleFormUpdate} className='MuiInput-input MuiInputBase-input MuiInputBase-inputSizeSmall css-nz481w-MuiInputBase-input-MuiInput-input' />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{paddingTop: 10, justifyContent: "flex-end"}}>
                            <Grid item>
                                <Button variant="outlined" onClick={closeModal}>Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained"
                                    disabled={!(
                                            formFirstName && 
                                            formLastName && 
                                            formPhoneNumber && 
                                            formBirthday
                                        )}
                                    onClick={handleSubmit}
                                >Submit</Button>
                            </Grid>
                        </Grid>
                        <Modal open={formShowErrors}>
                            <Paper style={{...modalStyle, maxWidth: 400, color: "DarkRed", fontWeight: "bold"}}>
                                <Grid container justifyContent={"flex-end"}>
                                    <Grid item>
                                        <IconButton onClick={() => { setFormShowErrors(false) }}>
                                            <Close fontSize='small' />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                
                                { 
                                formErrors.map((err) => {
                                    return <Typography key={Math.floor(Math.random() * 50)} style={{paddingBottom: 10}}>{err}</Typography>
                                })
                                }
                            </Paper>
                        </Modal>
                        </Paper>
                    
                </Modal>

                <Snackbar open={SBOpen} autoHideDuration={3000} onClose={handleSBClose}>
                    <Alert severity={SBSeverity}>
                        {SBMessage}
                    </Alert>
                </Snackbar>
            </>
            : 
            <div backgroundColor="white" >
                <Typography>Loading...</Typography>
            </div>
        }

    </div>
);



}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    maxWidth: 500,
    padding: 15,
    paddingLeft: 25,
    paddingRight: 25
}


export default Students