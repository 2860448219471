import React, { useState } from 'react'
import { Button, ButtonGroup, Popover, Stack } from '@material-ui/core';
import { DateBox } from 'devextreme-react';


const WeekButtons = ({ data, width, student, updateRecurrence }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorText, setAnchorText] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const handleClick = (event) => {
        let tgt = event.currentTarget
        let tgtTxt = tgt.innerText

        setAnchorEl(tgt);
        setAnchorText(tgtTxt)

        if (data && data[tgtTxt]) {
            setStartTime(data[tgtTxt].startTime)
            setEndTime(data[tgtTxt].endTime)
        } else {
            setStartTime(null)
            setEndTime(null)
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorText(null);
    };

    const handleUpdate = () => {
        if (!startTime || !endTime) return

        var newData = (data) ? JSON.parse(JSON.stringify(data)) : {}
        let strt = new Date(startTime)
        let end = new Date(endTime)

        if (data && newData[anchorText]) {
            newData[anchorText].startTime = strt.toString()
            newData[anchorText].endTime = end.toString()
        } else {
            let newEntry = {
                startTime: strt.toString(),
                endTime: end.toString()
            }
            newData[anchorText] = newEntry
        }
        updateRecurrence(newData, student)
        handleClose()
    }

    const handleRemove = () => {
        var newData = JSON.parse(JSON.stringify(data))
        if (data && newData[anchorText]) {
            delete newData[anchorText]
        }
        if (Object.keys(newData).length === 0) {
            updateRecurrence(null, student)
        } else {
            updateRecurrence(newData, student)
        }
        handleClose()
    }

    const updateStart = (value) => {
        let newStart = new Date(value)
        let curEnd = new Date(endTime)
        
        if (!endTime || newStart > curEnd) {
            //if end time is not set, or if start time is later than end time
            let newEnd = new Date(newStart.getTime()+3600*1000)
            setEndTime(newEnd)
        } 

        setStartTime(value)
    }

    const updateEnd = (value) => {
        let newEnd = new Date(value)
        let curStart = new Date(startTime)

        if (!startTime || newEnd < curStart) {
            //if start time is not set, or if end time is sooner than start time
            let newStart = new Date(newEnd.getTime() - 3600*1000)
            setStartTime(newStart)
        }

        setEndTime(value)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <ButtonGroup size="small" style={{margin: "auto", paddingTop: ".5em"}}>
                <Button variant={(data && data["SUN"]) ? "contained" : "outlined"} color={(anchorEl && anchorText === "SUN") ? "secondary" : "primary"} onClick={handleClick}>Sun</Button>
                <Button variant={(data && data["MON"]) ? "contained" : "outlined"} color={(anchorEl && anchorText === "MON") ? "secondary" : "primary"} onClick={handleClick}>Mon</Button>
                <Button variant={(data && data["TUE"]) ? "contained" : "outlined"} color={(anchorEl && anchorText === "TUE") ? "secondary" : "primary"} onClick={handleClick}>Tue</Button>
                <Button variant={(data && data["WED"]) ? "contained" : "outlined"} color={(anchorEl && anchorText === "WED") ? "secondary" : "primary"} onClick={handleClick}>Wed</Button>
                <Button variant={(data && data["THU"]) ? "contained" : "outlined"} color={(anchorEl && anchorText === "THU") ? "secondary" : "primary"} onClick={handleClick}>Thu</Button>
                <Button variant={(data && data["FRI"]) ? "contained" : "outlined"} color={(anchorEl && anchorText === "FRI") ? "secondary" : "primary"} onClick={handleClick}>Fri</Button>
                <Button variant={(data && data["SAT"]) ? "contained" : "outlined"} color={(anchorEl && anchorText === "SAT") ? "secondary" : "primary"} onClick={handleClick}>Sat</Button>
            </ButtonGroup>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Stack spacing={2} style={{padding: 10}}>
                    <DateBox 
                        adaptivityEnabled={(width < 960) ? true : false}
                        label="Start Time"
                        name="startTime"
                        onValueChange={updateStart}
                        showDropDownButton={false}
                        pickerType="native"
                        type="time"
                        value={startTime}
                    />
                    <DateBox 
                        adaptivityEnabled={(width < 960) ? true : false}
                        label="End Time"
                        name="endTime"
                        onValueChange={updateEnd}
                        showDropDownButton={false}
                        pickerType="native"
                        type="time"
                        value={endTime}
                    />
                    <Button onClick={handleUpdate} disabled={!endTime || !startTime}>
                        Update
                    </Button>
                    <Button onClick={handleRemove} color="warning" >
                        Remove
                    </Button>
                </Stack>
            </Popover>
        </div>
    )
}

WeekButtons.defaultProps = {
    data: null,
    width: 500
}

export default WeekButtons