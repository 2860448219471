import React from "react";
import { Switch, Route } from "react-router-dom";

import Dashboard from "./Dashboard";
import Students from "./Students";
import Schedule from "./Schedule";

export const Trainer = () => {
  return (
    <div>
        <div className="pages">
            <Switch>
                <Route exact path="/trainer" component={Dashboard} />
                <Route path="/trainer/students" component={Students} />
                <Route path="/trainer/schedule" component={Schedule} />
            </Switch>
        </div>
    </div>
  );
};
