import React from "react";
import Scheduler, { Resource } from "devextreme-react/scheduler";
import SpeedDialAction from "devextreme-react/speed-dial-action";
import ContextMenu from "devextreme-react/context-menu";
import CustomStore from 'devextreme/data/custom_store';
import SelectBox from "devextreme/ui/select_box";
import { confirm } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { Modal, Paper, Grid, Button, Typography } from "@material-ui/core";


import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './styles.css';

import Appointment from "./resources/Appointment";
import { lessonStatus, paidStatus } from "./resources/LessonStatus";
import { DateBox } from "devextreme-react";

const views = ["agenda", "day", "week", "month"];
const resourcesList = ["Status", "Payment"]

const appointmentClassName = '.dx-scheduler-appointment';
const cellClassName = '.dx-scheduler-date-table-cell';

const handleErrors = (response) => {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const loadData = async () => {
    let timeZone = "-0"+((new Date()).getTimezoneOffset() / 60)+"00"
    return fetch(process.env.REACT_APP_API_URL + `RRFLessons.php?tz=${encodeURIComponent(timeZone)}`)
            .then(handleErrors)
            .then(response => response.text())
            .then(response => {
                return JSON.parse(response)
            })
            .catch(() => { throw Object.assign(
                new Error("Network Error"),
                { code: 402 }
            ) });
}

const lessonStore = new CustomStore({
    key: 'lesson_id',
    load: () => {
        return loadData()
    },
    insert: (values) => {
        // console.log("insert", values)
        var date = new Date(values.date)
        var start = new Date(values['startDate']) 
        var end = new Date(values['endDate']) 
        start.setDate(date.getDate())
        start.setMonth(date.getMonth())
        start.setFullYear(date.getFullYear())
        end.setDate(date.getDate())
        end.setMonth(date.getMonth())
        end.setFullYear(date.getFullYear())

        var corrected = values
        corrected['startDate'] = start.toString()
        corrected['endDate'] = end.toString()

        delete corrected['date']
        delete corrected['text']

        return fetch(process.env.REACT_APP_API_URL + "RRFLessons.php", {
            method: "POST",
            body: JSON.stringify(corrected)
        })
        // .then(res => {
        //     console.log(res.text())
        //     return res
        // })
        .then(res => res.json())
        .then(out => notify(out['message'], out['type'], 1000))
        .then(() => { return loadData() })

    },
    update: (key, values) => {
        var corrected = values

        let start = new Date(values['startDate'])
        let end = new Date(values['endDate'])

        corrected['startDate'] = start.toString()
        corrected['endDate'] = end.toString()

        delete corrected['color']
        delete corrected['date']
        delete corrected['horseName']
        delete corrected['text']

        return fetch(process.env.REACT_APP_API_URL + "RRFLessons.php?id="+key, {
            method: "PUT",
            body: JSON.stringify(corrected)
        })
        .then(res => res.json())
        .then(out => notify(out['message'], out['type'], 1000))
        .then(() => { return loadData() })

    },
    remove: (key) => {
        return fetch(process.env.REACT_APP_API_URL + "RRFLessons.php?id="+key, {
            method: "DELETE"
        })
        .then(res => res.json())
        .then(out => notify(out['message'], out['type'], 1000))
        .then(() => { return loadData() })
    },
})

// const horseStore = new CustomStore({
//     key: 'id',
//     load: () => {
//         console.log("load horses")
//         //  ADD API FOR FETCH HORSES
//         return fetch(process.env.REACT_APP_API_URL + "RRFHorses.php")
//             .then(handleErrors)
//             .then(response => response.text())
//             .then(text => text.split("<")[0])
//             // .then(resp => console.log(resp))
//             .then(response => JSON.parse(response))
//             .then(res => {
//                 // console.log(res)
//                 return res
//             })
//             .catch(() => { throw 'Network error' });
//     },
// })

const AppointmentMenuTemplate = (itemData) => {
    return (
        <div>
            {itemData.text}
        </div>
    )
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            horseData: [],
            disabled: true,
            studentData: [],
            modalOpen: false,
            cellDuration: 30, 
            allowAdding: true,
            allowDeleting: true,
            allowUpdating: true,
            contextMenuItems: [],
            currentDate: new Date(),
            width: window.innerWidth,
            generatorEndDate: Date(),
            generatorStartDate: Date(),
            target: appointmentClassName,
            colorViewValue: resourcesList[0], 
        };

        this.onContentReady = this.onContentReady.bind(this);
        this.generateLessons = this.generateLessons.bind(this);
        this.onCellContextMenu = this.onCellContextMenu.bind(this);
        this.colorSelectorChanged = this.colorSelectorChanged.bind(this);
        this.showAppointmentPopup = this.showAppointmentPopup.bind(this);
        this.onAppointmentContextMenu = this.onAppointmentContextMenu.bind(this)
        this.onAppointmentFormOpening = this.onAppointmentFormOpening.bind(this); 

        this.schedulerRef = React.createRef();
    }

    updateDimensions = () => {
        const prevWidth = this.state.width
        this.setState({width: window.innerWidth})

        if (prevWidth >= 500 && window.innerWidth < 500) {
            let selectElement = document.getElementsByClassName("dx-toolbar-center")
            selectElement[0].classList.add('dx-state-invisible')
            selectElement[0].setAttribute("display", "none")
        } else
        if (prevWidth < 500 && window.innerWidth >= 500) {
            let selectElement = document.getElementsByClassName("dx-toolbar-center")
            selectElement[0].classList.remove('dx-state-invisible')
            selectElement[0].setAttribute("display", "block")
        }
        
    }

    //loads students and horses for use in scheduling
    componentDidMount() {
        // let mounted = true
        //load students
        fetch(process.env.REACT_APP_API_URL + "RRFStudents.php")
        .then(handleErrors)
        .then(response => response.text())
        .then(str => str.replace('~', ''))
        .then(response => JSON.parse(response))
        .then(res => {
            // if (mounted) {
                this.setState({
                    studentData: res
                })
            // }
        })
        .catch(() => { throw Object.assign(
            new Error("Network Error"),
            { code: 402 }
        ) });
        
        //load horses
        fetch(process.env.REACT_APP_API_URL + "RRFHorses.php")
        .then(handleErrors)
        .then(response => response.text())
        .then(str => str.replace('~', ''))
        .then(response => JSON.parse(response))
        .then(res => {
            this.setState({
                horseData: res
            })
        })
        .catch(() => { throw Object.assign(
            new Error("Network Error"),
            { code: 402 }
        ) });

        window.addEventListener('resize', this.updateDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    render() {
        return (
            <React.Fragment>
                <div style={{height: "100%"}}>
                    <Scheduler
                        views={views}
                        height="100%"
                        startDayHour={9}
                        ref={this.schedulerRef}
                        dataSource={lessonStore}
                        onContentReady={this.onContentReady}
                        currentDate={this.state.currentDate}
                        cellDuration={this.state.cellDuration}
                        appointmentTooltipComponent={Appointment}
                        onCellContextMenu={this.onCellContextMenu}
                        onAppointmentFormOpening={this.onAppointmentFormOpening}
                        onAppointmentContextMenu={this.onAppointmentContextMenu}
                        adaptivityEnabled={(this.state.width < 960) ? true : false}
                        defaultCurrentView={(this.state.width < 960) ? "agenda" : "month"}
                    >
                    {/* <Resource
                        dataSource={horseStore}
                        fieldExpr="horse"
                        label="Horse"
                    /> */}
                    <Resource
                        dataSource={lessonStatus}
                        fieldExpr="status"
                        label="Status"
                        useColorAsDefault={this.state.colorViewValue === "Status"}
                    />
                    <Resource
                        dataSource={paidStatus}
                        fieldExpr="paid"
                        label="Paid"
                        useColorAsDefault={this.state.colorViewValue === "Payment"}
                    />
                    
                    </Scheduler>
                    <ContextMenu
                        dataSource={this.state.contextMenuItems}
                        width={200}
                        target={this.state.target}
                        disabled={this.state.disabled}
                        onItemClick={this.onContextMenuItemClick}
                        itemRender={AppointmentMenuTemplate}
                    />

                    <SpeedDialAction icon="plus" hint="Add Lesson" onClick={this.showAppointmentPopup} />
                    <SpeedDialAction icon="redo" hint="Generate Lessons" onClick={() => {this.setState((items) => ({...items, modalOpen: true }))}} />

                    <Modal open={this.state.modalOpen}>
                        <Paper style={modalStyle}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Generate Lessons</Typography>
                                    <Typography variant="body2">This prompt generates new lessons based on the weekly lessons times and default horse and price optoins for each student.</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateBox 
                                        id="generator-start"
                                        label="Start Date"
                                        onValueChanged={(e) => {
                                            let date = new Date(e.value)
                                            let endDate = new Date(this.state.generatorEndDate)

                                            if (date > endDate) {
                                                this.setState((items) => ({
                                                    ...items,
                                                    generatorEndDate: date
                                                }))
                                            }
                                            
                                            this.setState((items) => ({
                                                ...items,
                                                generatorStartDate: date
                                            }))
                                        }}
                                        value={this.state.generatorStartDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateBox 
                                        id="generator-end"
                                        label="End Date"
                                        onValueChanged={(e) => {
                                            let date = new Date(e.value)
                                            let startDate = this.state.generatorStartDate

                                            if (date < startDate) {
                                                this.setState((items) => ({
                                                    ...items,
                                                    generatorStartDate: date
                                                }))
                                            }

                                            this.setState((items) => ({
                                                ...items,
                                                generatorEndDate: date
                                            }))
                                        }}
                                        value={this.state.generatorEndDate}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="outlined" onClick={() => {this.setState((items) => ({
                                        ...items,
                                        modalOpen: false
                                    }))}}>Cancel</Button>
                                </Grid>
                                <Grid item xs={6} style={{textAlign: "right"}}>
                                    <Button variant="contained" onClick={this.generateLessons}>Confirm</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }

    switcherAdded = false

    generateLessons() {
        const scheduler = this.schedulerRef.current.instance;
        let stdt = new Date(this.state.generatorStartDate)
        let eddt = new Date(this.state.generatorEndDate)

        fetch(process.env.REACT_APP_API_URL + "RRFGenerate.php", {
            method: "POST", 
            body: JSON.stringify({
                "start": stdt.toString(),
                "end": eddt.toString(),
            })
        })
        // .then(handleErrors)
        .then(() => {this.setState((items) => ({
            ...items,
            modalOpen: false
        }))})
        .then(scheduler.getDataSource().reload())
    }

    onContentReady(e) {
        if (this.switcherAdded) return

        let element = document.querySelectorAll('.dx-toolbar-center')
        const container = document.createElement("div")
        container.setAttribute('id', 'colorSelectContainer')

        element[0].appendChild(container)

        if (this.state.width < 500) {
            let selectContainer = document.getElementsByClassName("dx-toolbar-center")
            selectContainer[0].classList.add('dx-state-invisible')
        }

        new SelectBox(container, {
            label: "Colored By",
            dataSource: resourcesList,
            value: this.state.colorViewValue,
            onValueChanged: this.colorSelectorChanged
        })

        this.switcherAdded = true
    }

    colorSelectorChanged(args) {
        this.setState({colorViewValue: args.value})
    }

    showAppointmentPopup() {
        this.schedulerRef.current.instance.showAppointmentPopup();
    }

    onAppointmentFormOpening(e) {
        const scheduler = this.schedulerRef.current.instance;
        const { form, popup } = e;
        const lessonData = e.appointmentData
        const currentStudentData = this.state.studentData

        var lessonStart = new Date(lessonData.startDate)
        var lessonEnd = new Date(lessonData.endDate)

        form.option('items', [
            {
                label: {
                    text: 'Student',
                },
                editorType: 'dxSelectBox',
                dataField: 'student',
                isRequired: true,
                editorOptions: {
                    items: this.state.studentData,
                    displayExpr: 'fullName',
                    valueExpr: 'id',
                    onValueChanged(args) {
                        const found = currentStudentData.find(elem => elem.id === args.value)
                        if (found) { form.updateData('price', found.price) };
                    },
                },
            }, {
                label: {
                    text: 'Price',
                },
                name: 'price',
                editorType: 'dxNumberBox',
                isRequired: true,
                editorOptions: {
                    require: true,
                    value: lessonData.price,
                    showSpinButtons: true,
                    step: 5,
                    min: 0,
                    onValueChanged(args) {
                        form.updateData('price', String(args.value))
                    }
                },
                
            }, {
                label: {
                    text: 'Horse',
                },
                editorType: 'dxSelectBox',
                dataField: 'horse',
                isRequired: true,
                editorOptions: {
                    require: true,
                    items: this.state.horseData,
                    displayExpr: 'text',
                    valueExpr: 'id',
                },
            }, {
                label: {
                    text: 'Date',
                },
                dataField: 'date',
                editorType: 'dxDateBox',
                isRequired: true,
                editorOptions: {
                    width: '100%',
                    type: 'date',
                    adaptivityEnabled: true,
                    onValueChanged(args) {
                        let newDate = new Date(args.value)

                        lessonStart.setDate(newDate.getDate())
                        lessonStart.setMonth(newDate.getMonth())
                        lessonStart.setFullYear(newDate.getFullYear())
                        lessonEnd.setDate(newDate.getDate())
                        lessonEnd.setMonth(newDate.getMonth())
                        lessonEnd.setFullYear(newDate.getFullYear())

                        form.updateData('startDate', lessonStart)
                        form.updateData('endDate', lessonEnd)
                    }
                },
            }, {
                label: {
                    text: 'Start Time',
                },
                dataField: 'startDate',
                editorType: 'dxDateBox',
                isRequired: true,
                editorOptions: {
                    width: '100%',
                    type: 'time',
                    pickerType: 'list',
                    adaptivityEnabled: true,
                    onValueChanged(args) {
                        var startDate = new Date(args.value);
                        lessonStart = startDate;
                        var endDate = new Date(startDate.getTime() + 3600 * 1000 );
                        lessonEnd = endDate;
                        form.updateData('endDate', endDate);
                    },
                },
            }, {
                label: {
                    text: 'End Time',
                },
                name: 'endDate',
                dataField: 'endDate',
                editorType: 'dxDateBox',
                isRequired: true,
                editorOptions: {
                    width: '100%',
                    type: 'time',
                    pickerType: 'list',
                    adaptivityEnabled: true,
                    onValueChanged(args) {
                        var endDate = new Date(args.value);
                        lessonEnd = endDate;
                        if (lessonEnd <= lessonStart) {
                            var startDate = new Date(endDate.getTime() - 3600 * 1000 );
                            lessonStart = startDate;
                            form.updateData('startDate', startDate);
                        }
                    },
                },
            }, {
                label: {
                    text: 'Status',
                },
                editorType: 'dxSelectBox',
                dataField: 'status',
                isRequired: true,
                editorOptions: {
                    items: lessonStatus,
                    displayExpr: 'text',
                    valueExpr: 'id',
                },
            }, {
                label: {
                    text: 'Paid',
                },
                editorType: 'dxSelectBox',
                dataField: 'paid',
                isRequired: true,
                editorOptions: {
                    items: paidStatus,
                    displayExpr: 'text',
                    valueExpr: 'id',
                },
            },
        ]);

        var currentButtons = popup.option('toolbarItems')

        currentButtons.forEach((element) => {
            element['toolbar'] = "bottom"
            if (element.shortcut === 'cancel') {
                element['location'] = 'before'
            }
        })

        if (parseInt(lessonData.status) < 2) {
            var deleteButton = {
                widget: 'dxButton',
                location: "before",
                toolbar: "bottom",
                options: {
                    text: "Delete",
                    onClick: () => {
                        var result = confirm("Are you sure?", "Confirm Deletion");
                        result.done((r) => {
                            if (r) {
                                scheduler.deleteAppointment(lessonData)
                            }
                        })
                    }
                },
            }
            currentButtons.unshift(deleteButton)
        }
        popup.option('toolbarItems', currentButtons)
    }

    onAppointmentContextMenu({ appointmentData, targetedAppointmentData }) {
        const scheduler = this.schedulerRef.current.instance;

        const lessonStatusItems = lessonStatus.map((item) => ({
            ...item,
            onItemClick: ({ itemData }) => {
                scheduler.updateAppointment(appointmentData, {
                    ...appointmentData,
                    ...{ status: itemData.id },
                })
            },
        }));
        const paidStatusItems = paidStatus.map((item) => ({
            ...item,
            onItemClick: ({ itemData }) => {
                scheduler.updateAppointment(appointmentData, {
                    ...appointmentData,
                    ...{ paid: itemData.id }
                })
            }
        }))
        // const horseItems = this.state.horseData.map((item) => ({
        //     ...item,
        //     disabled: (() => {
        //         return (targetedAppointmentData.horse === item.id) ? "true" : "false" 
        //     }),
        //     onItemClick: ({itemData}) => {
        //         scheduler.updateAppointment(appointmentData, {
        //             ...appointmentData,
        //             ...{ horse: itemData.id }
        //         })
        //     }
        // }))
        this.setState((state) => ({
            ...state,
            
            target: appointmentClassName,
            disabled: false,
            contextMenuItems: [{
                text: 'Open',
                onItemClick: () => scheduler.showAppointmentPopup(appointmentData),
            },
            ...((parseInt(appointmentData.status) < 2) ? [
            {
                text: 'Delete',
                onItemClick: () => {
                    scheduler.deleteAppointment(appointmentData)
                },
            }] : []),
            {
                text: 'Set Status', 
                beginGroup: true, 
                disabled: true 
            },
                ...lessonStatusItems,

            {
                text: 'Set Payment',
                beginGroup: true,
                disabled: true 
            },
                ...paidStatusItems
            ],
        }));
    }

    onContextMenuItemClick(e) {
        e.itemData.onItemClick(e);
    }

    onCellContextMenu({ cellData }) {
        const scheduler = this.schedulerRef.current.instance;
        this.setState((state) => ({
            ...state,
            target: cellClassName,
            disabled: false,
            contextMenuItems: [{
                text: 'New Appointment',
                onItemClick: () => {
                    scheduler.showAppointmentPopup(
                        { date: cellData.startDate },
                        true,
                    )
                },
            }, {
                text: 'Go to Today',
                onItemClick: () => {
                    this.setState((currentState) => ({
                    ...currentState,
                    currentDate: new Date(),
                    }));
                },
            }],
        }));
    }

}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95vw",
    maxWidth: 500,
    padding: 15,
    paddingLeft: 25,
    paddingRight: 25
}

export default App;
