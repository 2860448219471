import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import LoginLogoutButton from "../LoginLogoutButton";
import "./PublicNavBar.css";

function PublicNavBar({ loginData, setLoginData }) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            Rich Run Farm
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {/* <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/guidelines"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Guidelines
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/resources"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Resources
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li> */}
            {   (loginData) ?
                <li className="nav-item">
                    <NavLink
                        exact
                        to="/trainer"
                        activeClassName="active"
                        className="nav-links"
                        onClick={handleClick}
                    >
                        Trainer
                    </NavLink>
                </li>
                : null
            }
            <li className="nav-item">
                <div className="nav-links margin-adjust">
                    <LoginLogoutButton loginData={loginData} setLoginData={setLoginData} />
                </div>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default PublicNavBar;
